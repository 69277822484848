<template>
  <section class="container-fluid pa-0 parceiros__container">
    <nav
      v-if="this.$route.meta.subpage !== true"
      class="container about__page_nav d-flex justify-center align-center pt-3 col-12 col-md-8"
    >
      <v-col
        class="about__page_nav-link d-flex justify-center flex-column align-center"
      >
        <router-link :to="{ name: 'projeto' }">
          O que é
        </router-link>
      </v-col>
      <v-col
        class="about__page_nav-link d-flex justify-center flex-column align-center"
      >
        <router-link :to="{ name: 'mapa_comunitario' }">
          Mapas Comunitários
        </router-link>
      </v-col>
      <v-col
        class="about__page_nav-link d-flex justify-center flex-column align-center"
      >
        <router-link :to="{ name: 'ficha_tecnica' }">
          Ficha Técnica
        </router-link>
      </v-col>
      <v-col
        class="about__page_nav-link d-flex justify-center flex-column align-center"
      >
        <router-link :to="{ name: 'projeto_parceiros' }">
          Parceiros
        </router-link>
      </v-col>
    </nav>

    <v-row class="d-flex justify-center align-center parceiros__section">
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center box__parceiros"
      >
        <router-link
          class="d-flex justify-center align-center"
          :to="{ name: 'projeto_cabf' }"
        >
          <v-img max-width="70%" src="../assets/projeto_card_cabf.png"></v-img>
        </router-link>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex justify-center align-center box__parceiros"
      >
        <router-link
          class="d-flex justify-center align-center"
          :to="{ name: 'projeto_sirene' }"
        >
          <v-img
            max-width="70%"
            src="../assets/projeto_card_sirene.png"
          ></v-img>
        </router-link>
      </v-col>
    </v-row>
  </section>
</template>

<script>
export default {
  data() {
    return {
      breadcrumbItems: [
        {
          text: "<      Quem somos",
          href: "cards",
        },
        {
          text: "      Assessoria Técnica      /",
          href: "sobre_assessoria_tecnica",
        },
        {
          text: "      Assessoria Jurídica",
          href: "sobre_assessoria_juridica",
        },
      ],
    };
  },
};
</script>

<style scoped>
a {
  color: #e06919;
}

.router-link-exact-active {
  background-image: url("../assets/link_bottom.png");
  width: 50%;
}

.parceiros__container {
  background-image: url("../assets/projeto_parceiros_background_01.png");
  background-size: cover;
  background-position: center center;
  height: 100%;
}

.box__parceiros {
  position: relative;
}

.box__parceiros img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box__parceiros a {
  position: absolute;
  max-width: 100%;
}

.card__row {
  height: 80%;
}

.card__row_link {
  cursor: pointer;
}

.parceiros__section {
  height: 80%;
  width: 100%;
}

section {
  background-color: #f9f7ed;
  padding-top: 2em;
  padding-bottom: 2em;
}
h5,
span,
a {
  color: #e06919;
}

p {
  font-size: 0.7em;
}

.text__box {
  max-width: 80%;
}

img {
  width: 15%;
}

.rodape img {
  width: 8em;
}

.rodape span {
  font-size: 0.7em;
  text-align: center;
}

@media (max-width: 600px) {
  section {
    padding-bottom: 0;
    padding-top: 0;
  }
  img {
    width: 60%;
  }
}
</style>
